/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ETextStyleVariant, GeneralInfoIcon, Radio, RadioGroup, Text } from '@outdoorsyco/bonfire';
import Image from 'next/image';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { formatListingLocation } from '@/components/bill/BillModuleDelivery/BillModuleDelivery';
import { TCurrency } from '@/config/locales';
import { getCurrency } from '@/redux/selectors/currency';
import { ICampgroundImage, ICampgroundLocation } from '@/services/types/search/campgrounds/id';
import { formatCurrency } from '@/utility/currency';

import { CampgroundPreviewModal } from './CampgroundPreviewModal';

interface ICampgroundCardProps {
  name: string;
  location: ICampgroundLocation;
  unavailable: boolean;
  currency: TCurrency;
  lowestPrice: number;
  handleSelectedCampground: (id: string) => void;
  id: number;
  imageUrl: string;
  isSelected: boolean;
  images: ICampgroundImage[];
  description: string;
  odnId?: string;
  score: number;
  reviewsNum: number;
}

export const CampgroundCard = ({
  name,
  location,
  unavailable,
  currency,
  lowestPrice,
  handleSelectedCampground,
  isSelected,
  imageUrl,
  id,
  images,
  description,
  odnId,
  score,
  reviewsNum,
}: ICampgroundCardProps) => {
  const defaultCurrency = useSelector(getCurrency);

  const formattedLocation = formatListingLocation(location);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const price = formatCurrency({
    currency: currency || defaultCurrency,
    digits: 0,
    maxDigits: 0,
    priceInCents: lowestPrice,
  });

  const intl = useIntl();
  let borderColor = isSelected
    ? 'border-primary-base hover:border-primary-base'
    : 'border-[#909398] hover:border-primary-base';
  if (unavailable) {
    borderColor = 'border-gray-310';
  }

  const handleOpenInfoModal = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setOpenInfoModal(true);
  }, []);

  const closeInfoModal = useCallback(() => setOpenInfoModal(false), []);

  return (
    <>
      <div
        className={`border-solid cursor-pointer rounded py-5 px-4 border md:border-2 flex items-center justify-between ${borderColor}`}
        onClick={() => handleSelectedCampground(String(id))}>
        <div className="flex items-center">
          <span className="relative flex w-20 h-20">
            <button
              className="absolute z-10 flex items-center justify-center w-8 h-8 border rounded-full bg-primary-contrast border-gray-310 -top-3 -left-3"
              onClick={handleOpenInfoModal}>
              <GeneralInfoIcon width={16} height={16} />
            </button>
            <Image src={imageUrl} alt={name} width={80} height={80} className="rounded-lg" />
          </span>

          <div className="flex flex-col ml-4 overflow-hidden gap-0.5 ">
            <Text
              variant={ETextStyleVariant.MediumBold}
              className="overflow-hidden text-ellipsis whitespace-nowrap">
              {name}
            </Text>

            <Text variant={ETextStyleVariant.SmallRegular}>{formattedLocation}</Text>

            <Text variant={ETextStyleVariant.SmallRegular}>
              {intl.formatMessage(
                { defaultMessage: 'Starting at {price} / night', id: 'gaGSKf' },
                { price },
              )}
            </Text>
          </div>
        </div>

        <RadioGroup
          inputValue={!unavailable ? String(isSelected) : ''}
          aria-label={name}
          onChange={() => handleSelectedCampground(String(id))}
          name={name}
          className="self-start">
          <Radio ariaLabel={name} value={'true'} disabled={unavailable} />
        </RadioGroup>
      </div>
      <CampgroundPreviewModal
        isOpen={openInfoModal}
        onClose={closeInfoModal}
        onSubmit={() => handleSelectedCampground(String(id))}
        images={images}
        location={formattedLocation}
        name={name}
        description={description}
        isSelected={isSelected}
        odnId={odnId}
        score={score}
        reviewsNum={reviewsNum}
      />
    </>
  );
};
