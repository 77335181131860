import {
  Button,
  EButtonColorVariant,
  EIconButtonVariant,
  ETextStyleVariant,
  GeneralBulbIcon,
  GeneralCloseIcon,
  IconButton,
  Overlay,
  Text,
} from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMenuTriggerState } from 'react-stately';

import { useGuidedSearchContext } from '@/components/guided-search/GuidedSearchContext';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useQueryParams from '@/hooks/useQueryParams';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import { getCookie, setCookie } from '@/utility/cookie';

import css from './HelpMeSearchButton.module.css';

const PROMO_COOKIE_NAME = 'guided-search-promo-shown';
const COOKIE_EXPIRATION_DATE = 400; // in days, upper limit of chrome cookie expiration

interface IProps {
  onClick: () => void;
}

const HelpMeSearchButton: React.FC<IProps> = ({ onClick }) => {
  const { isMobile } = useBreakpoint();
  const { reset: resetGuidedSearch, hasSelectedData } = useGuidedSearchContext();
  const [showNotification, setShowNotification] = useState<null | boolean>(null);
  const deliveryRevertDecision = useExperimentIsEnabled(
    OptimizelyFlags.REVERT_SETUP_DELIVERY_CHANGE,
  );
  const overlayRef = useRef<HTMLButtonElement>(null);
  const queryParams = useQueryParams();
  const overlayTriggerState = useMenuTriggerState({
    isOpen: Boolean(showNotification),
    onOpenChange: () => {
      setShowNotification(false);
    },
  });

  useEffect(() => {
    if (isMobile && showNotification === null) {
      const promoCookie = getCookie(PROMO_COOKIE_NAME);

      if (!promoCookie || promoCookie !== 'true') {
        setShowNotification(true);
        setCookie(PROMO_COOKIE_NAME, 'true', { expires: COOKIE_EXPIRATION_DATE });

        setTimeout(() => {
          setShowNotification(false);
        }, 5000);
      } else {
        setShowNotification(false);
      }
    }
  }, [isMobile, showNotification]);
  const isDelivery = queryParams.delivery === 'true';
  const isDeliveryStationary = queryParams.deliveryStationary === 'stationary';
  useEffect(() => {
    if (isMobile && deliveryRevertDecision && isDelivery && isDeliveryStationary) {
      setShowNotification(false);
    }
  }, [isMobile, deliveryRevertDecision, isDelivery, isDeliveryStationary]);

  const handleClick = () => {
    if (showNotification) {
      setShowNotification(false);
    }
    resetGuidedSearch();
    onClick?.();
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  if (isMobile) {
    return (
      <div>
        <Button
          className={cn(
            'relative flex items-center px-4 border rounded-full border-gray-310 whitespace-nowrap h-[2.125rem] md:h-10',
            {
              '!bg-gray-800 !border-gray-800': hasSelectedData,
            },
          )}
          ref={overlayRef}
          label={<GeneralBulbIcon className={cn({ 'text-white': hasSelectedData })} />}
          variant={EButtonColorVariant.Secondary}
          onClick={handleClick}
        />
        {showNotification && (
          <Overlay triggerRef={overlayRef} triggerState={overlayTriggerState}>
            <div className="relative p-6 mx-4 text-white bg-gray-800 top-3 w-fit z-60 rounded-3xl max-w-[420px]">
              <div className={css.arrow} />
              <IconButton
                className="absolute rounded-full top-[8px] right-[8px] !bg-gray-50"
                icon={GeneralCloseIcon}
                size="small"
                variant={EIconButtonVariant.Transparent}
                onClick={handleCloseNotification}
              />
              <Text className="py-2" variant={ETextStyleVariant.MediumRegular}>
                <FormattedMessage
                  defaultMessage="Narrow results to find your perfect ride. Tap here."
                  id="sAG5A5"
                />
              </Text>
            </div>
          </Overlay>
        )}
      </div>
    );
  }

  return (
    <div className="items-center hidden h-10 md:flex md:h-11 bg-[linear-gradient(109deg,_#57B499_0%,_#1E604C_100%)] rounded-3xl p-0.5">
      <Button
        className="py-2 text-white border-none h-9 md:h-10 md:x-4 bg-[linear-gradient(109deg,_#093025_9.85%,_#246150_61.04%)] md:py-3 px-3.5 md:px-5.5"
        onClick={() => {
          resetGuidedSearch();
          onClick?.();
        }}
        variant={EButtonColorVariant.Secondary}
        label={
          <div className="flex flex-row items-center justify-center">
            <GeneralBulbIcon className="hidden w-6 h-6 mr-2 text-white md:inline" />
            <Text className="text-sm font-medium text-white lg:text-base">
              <FormattedMessage defaultMessage="Help me search" id="6tZiBh" />
            </Text>
          </div>
        }
      />
    </div>
  );
};

export default HelpMeSearchButton;
