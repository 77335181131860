import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React, { ReactNode, SVGProps } from 'react';

interface IDeliveryFilterOptionProps {
  label: string;
  IconName: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  onClick: () => void;
  children?: ReactNode;
}
export const DeliveryFilterOption = ({
  label,
  onClick,
  IconName,
  children,
}: IDeliveryFilterOptionProps) => {
  return (
    <button
      className="flex items-center justify-between w-full py-6"
      onClick={onClick}
      aria-label={label}>
      <div className="flex gap-3">
        <IconName width={24} height={24} />

        <Text variant={ETextStyleVariant.MediumRegular}>{label}</Text>
      </div>

      {children}
    </button>
  );
};
