import isFinite from 'lodash/isFinite';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { clearDeliveryCost } from '@/redux/modules/delivery';
import { updateDeliveryCost } from '@/redux/modules/delivery/deliveryCost';
import { TAppDispatch, TRootState } from '@/redux/rootReducer';
import { getBookingOrQuoteCurrency } from '@/redux/selectors/bill/data';
import {
  getDelivery,
  getDeliveryCostPerMile,
  getDeliveryRadius,
} from '@/redux/selectors/listing/delivery';
import { getListingData } from '@/redux/selectors/listing/page';
import {
  getQuoteDeliverableCampgrounds,
  getQuoteDelivery,
  getQuoteDeliveryCampgroundId,
} from '@/redux/selectors/quote';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import inlineAddressFormat from '@/services/inlineAddressFormat';
import { EDeliveryOption, TDeliveryFields } from '@/services/types/core/delivery.types';
import { IQuoteDeliveryLocation } from '@/services/types/quote/IDeliveryLocation';
import { ILocation } from '@/services/types/search/rentals/id';
import { formatCurrency } from '@/utility/currency';

import DeliveryModalLegacy from '../DeliveryModalLegacy/DeliveryModalLegacy';
import DeliveryModal from './DeliveryModal';

export enum DeliveryProposalFeeType {
  DELIVERY_AND_INSURANCE = 'delivery-and-insurance',
  INSURANCE_ONLY = 'insurance-only',
}

interface IProps {
  listingLocation?: ILocation;
  deliveryAddress?: string;
  deliveryLocation?: IQuoteDeliveryLocation;
  onClose: () => void;
  onSelectAddress?: (value?: IQuoteDeliveryLocation) => void;
  onDeliverySubmit: (data: TDeliveryFields, isStationary: boolean) => void;
  onPickupSubmit: () => void;
  open: boolean;
  deliveryType: EDeliveryOption;
  isProposalMode?: boolean;
  deliveryModalTitle?: string;
  isSubmitting?: boolean;
  isNegotiating?: boolean;
  deliveryFeeType?: DeliveryProposalFeeType;
  buttonLabel?: string;
  hideFooter?: boolean;
  onChangeDeliveryType: (value: EDeliveryOption) => void;
}

export const DeliveryContainer: React.FC<IProps> = ({
  listingLocation,
  deliveryAddress,
  deliveryLocation,
  onClose,
  onSelectAddress,
  onDeliverySubmit,
  onPickupSubmit,
  open,
  deliveryType,
  isProposalMode,
  deliveryModalTitle,
  isSubmitting,
  isNegotiating,
  buttonLabel,
  hideFooter,
  onChangeDeliveryType,
}) => {
  const intl = useIntl();
  const dispatch: TAppDispatch = useDispatch();
  const deliveryRevertDecision = useExperimentIsEnabled(
    OptimizelyFlags.REVERT_SETUP_DELIVERY_CHANGE,
  );
  const deliveryCostPerMile = useSelector(getDeliveryCostPerMile);
  const deliveryRadius = useSelector(getDeliveryRadius);
  const listingData = useSelector(getListingData);
  const currency = useSelector(getBookingOrQuoteCurrency);
  const [error, setError] = useState<React.ReactNode>();
  const quoteDelivery = useSelector(getQuoteDelivery);
  const quoteDeliverableCampgrounds = useSelector(getQuoteDeliverableCampgrounds);
  const quoteDeliveryCampgroundId = useSelector(getQuoteDeliveryCampgroundId);
  const { data, isLoading, error: deliveryError } = useSelector(getDelivery) || {};
  const outOfRangeError = intl.formatMessage(
    {
      defaultMessage:
        'The address entered is outside of the delivery radius for this RV.{br} You can message the host to discuss delivery options.',
      id: '/IYebR',
      description: 'UI > Delivery > Out of range error',
    },
    {
      br: <br />,
    },
  );
  const distanceError = intl.formatMessage({
    defaultMessage: 'Something went wrong. Please try again.',
    id: 'jdJVsf',
    description: 'UI > Delivery > Get MapBox driving distance error',
  });

  const quoteDeliveryPrice = useSelector<TRootState, number | undefined>(
    state => state.quote.data?.delivery_item?.price,
  );

  const price = isFinite(data?.price) ? data?.price : quoteDeliveryPrice;
  const deliveryCost = isFinite(price)
    ? formatCurrency({ priceInCents: price, currency, digits: 2 })
    : undefined;

  const deliveryFeeType =
    data && data.isSameAddress && data.insurancePriceDifference
      ? DeliveryProposalFeeType.INSURANCE_ONLY
      : DeliveryProposalFeeType.DELIVERY_AND_INSURANCE;

  const handleSelectAddress = useCallback(
    async (value?: IQuoteDeliveryLocation) => {
      if (!value) {
        return;
      }
      const { lat, lng } = value;
      if (lat == null || lng == null) {
        setError(outOfRangeError);
        return;
      }

      try {
        const isWithinRange = await dispatch(
          updateDeliveryCost(
            listingLocation,
            { lng, lat },
            deliveryType === EDeliveryOption.STATIONARY
              ? EDeliveryOption.STATIONARY
              : EDeliveryOption.MOVING,
          ),
        );
        switch (isWithinRange) {
          case 'FALSE':
            setError(outOfRangeError);
            break;
          case 'TRUE':
            setError('');
        }
      } catch {
        setError(distanceError);
      }
      onSelectAddress?.(value);
    },
    [onSelectAddress, outOfRangeError, dispatch, listingLocation, deliveryType, distanceError],
  );

  const handleAddressInputChange = useCallback(
    (resetDeliveryEstimate?: boolean) => {
      if (resetDeliveryEstimate) {
        dispatch(clearDeliveryCost());
      }
      if (error && resetDeliveryEstimate) {
        setError('');
      }
    },
    [error, dispatch],
  );

  if (!deliveryRadius || !listingLocation) {
    return null;
  }

  const costPerMile = deliveryCostPerMile
    ? formatCurrency({ priceInCents: deliveryCostPerMile, currency, digits: 2 })
    : undefined;

  const modalDeliveryAddress =
    deliveryAddress || (quoteDelivery?.location ? inlineAddressFormat(quoteDelivery.location) : '');
  return (
    <>
      {deliveryRevertDecision && listingData?.disallow_movement === false && (
        <DeliveryModalLegacy
          deliveryType={deliveryType}
          onChangeOption={onChangeDeliveryType}
          deliveryAddress={modalDeliveryAddress}
          deliveryCost={deliveryCost}
          deliveryCostPerMile={costPerMile}
          deliveryRadius={deliveryRadius}
          deliveryStationary={deliveryType === EDeliveryOption.STATIONARY}
          deliveryLocation={quoteDelivery?.location || deliveryLocation}
          distanceFromLocation={quoteDelivery?.estimated_distance || data?.estimated_distance}
          error={error || deliveryError}
          loading={isLoading}
          listingLocation={listingLocation}
          onClose={onClose}
          onAddressInputChange={handleAddressInputChange}
          onSelectAddress={handleSelectAddress}
          onDeliverySubmit={onDeliverySubmit}
          onPickupSubmit={onPickupSubmit}
          open={open}
          isProposalMode={isProposalMode}
          deliveryModalTitle={deliveryModalTitle}
          isSubmitting={isSubmitting}
          buttonLabel={buttonLabel}
          hideFooter={hideFooter}
          isNegotiating={isNegotiating}
          deliveryFeeType={deliveryFeeType}
        />
      )}
      {(!deliveryRevertDecision || (deliveryRevertDecision && listingData?.disallow_movement)) && (
        <DeliveryModal
          deliveryType={deliveryType}
          deliveryAddress={modalDeliveryAddress}
          deliveryCost={deliveryCost}
          deliveryRadius={deliveryRadius}
          deliveryLocation={quoteDelivery?.location || deliveryLocation}
          deliverableCampgrounds={quoteDeliverableCampgrounds}
          deliveryCampgroundId={quoteDeliveryCampgroundId}
          distanceFromLocation={data?.estimated_distance || quoteDelivery?.estimated_distance}
          error={deliveryType === EDeliveryOption.PICKUP ? undefined : error || deliveryError}
          loading={isLoading}
          listingLocation={listingLocation}
          onClose={onClose}
          onAddressInputChange={handleAddressInputChange}
          onSelectAddress={handleSelectAddress}
          onDeliverySubmit={onDeliverySubmit}
          onPickupSubmit={onPickupSubmit}
          open={open}
          isProposalMode={isProposalMode}
          isSubmitting={isSubmitting}
          isNegotiating={isNegotiating}
          deliveryFeeType={deliveryFeeType}
          onChangeDeliveryType={onChangeDeliveryType}
        />
      )}
    </>
  );
};
