import React from 'react';
import { useIntl } from 'react-intl';

import { CARET_LARGE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Link, { LinkVariants } from '@/components/switchback/Link/Link';

export const LearnMore = () => {
  const intl = useIntl();
  const linkText = intl.formatMessage({
    defaultMessage: 'Learn more about stationary delivery',
    id: 'YxJxhA',
  });

  const linkUrl = '/blog/stationary-rentals';
  return (
    <div className="mt-3">
      <Link
        data-testid="delivery-modal-learn-more-link"
        target="_blank"
        rel="noopener noreferrer"
        variant={LinkVariants.underlineHover}
        href={linkUrl}>
        {linkText}
        <Icon width={10} className="transform rotate-180" name={CARET_LARGE} />
      </Link>
    </div>
  );
};
