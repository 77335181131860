import {
  Button,
  EButtonColorVariant,
  EHeadingStyleVariant,
  EModalFooterVariants,
  EModalSize,
  EModalVariants,
  Heading,
  Modal,
} from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { useBreakpoint } from '@/hooks/useBreakpoint';

import css from './DeliveryModalLegacy.module.css';
interface ILegacyGettingRVOptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  onSubmit: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  footerMessage?: React.ReactNode;
  deliveryModalTitle?: string | undefined;
}

export const LegacyGettingRVOptionModal = ({
  isOpen,
  onClose,
  children,
  onSubmit,
  isLoading,
  isDisabled,
  footerMessage,
  deliveryModalTitle,
}: ILegacyGettingRVOptionModalProps) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoint();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      variant={isMobile ? EModalVariants.Bottom : undefined}
      size={EModalSize.Large}>
      <Modal.Actions />
      <Modal.Header>
        <Heading variant={EHeadingStyleVariant.H5}>
          {deliveryModalTitle ||
            intl.formatMessage({
              defaultMessage: 'How would you like to get your RV?',
              id: 'weYIIN',
            })}
        </Heading>
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Footer
        variant={EModalFooterVariants.Actions}
        className={`px-4 md:px-0 pt-4 md:pt-0 md:mt-2 md:mx-8 ${css.shadowBottom}`}>
        {footerMessage}
        <Button
          label={intl.formatMessage({ defaultMessage: 'Confirm', id: 'N2IrpM' })}
          onClick={onSubmit}
          variant={EButtonColorVariant.Primary}
          loading={isLoading}
          disabled={isDisabled}
          className="w-full md:w-auto"
        />
      </Modal.Footer>
    </Modal>
  );
};
