import React from 'react';

import RadioBox from '@/components/switchback/RadioBox/RadioBox';
import { EDeliveryOption } from '@/services/types/core/delivery.types';

interface IInsuranceOption {
  title: string;
  description: string;
}

interface IProps {
  name: string;
  insuranceOptions: Partial<{
    [EDeliveryOption.MOVING]: IInsuranceOption;
    [EDeliveryOption.STATIONARY]: IInsuranceOption;
  }>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onReset?: (e: React.MouseEvent<HTMLInputElement>) => void;
  insuranceValue: string;
}

const InsuranceOptions: React.FC<IProps> = ({
  name,
  insuranceValue,
  insuranceOptions,
  onChange,
  onReset,
}) => {
  return (
    <>
      {Object.entries(insuranceOptions).map(
        ([key, option]) =>
          option && (
            <div className="inline-flex flex-1" key={`${option.title}-${insuranceValue}`}>
              <RadioBox
                id={key}
                label={option.title}
                name={name}
                labelClassName="h-full"
                value={key}
                onChange={onChange}
                onClick={
                  onReset
                    ? e => {
                        if (e.currentTarget.value === insuranceValue) {
                          onReset(e);
                        }
                      }
                    : undefined
                }
                checked={insuranceValue === key}
                data-testid={`${key}-btn`}>
                {option.description}
              </RadioBox>
            </div>
          ),
      )}
    </>
  );
};

export default InsuranceOptions;
