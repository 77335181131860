import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import { getMeetsMinDeliveryCostReq } from '@/redux/selectors/listing/delivery';
import {
  EDeliveryFields,
  EDeliveryOption,
  TDeliveryFields,
} from '@/services/types/core/delivery.types';
import { IQuoteDeliveryLocation } from '@/services/types/quote/IDeliveryLocation';
import { ILocation } from '@/services/types/search/rentals/id';
import { itemizeAddress } from '@/utility/itemizeAddress';
import { formatMeasurement } from '@/utility/measurements';
import { mapPluralUnitToSingular } from '@/utility/units';

import { DeliveryProposalFeeType } from '../DeliveryModal/DeliveryContainer';
import { LegacyGettingRVOptionModal } from '../LegacyGettingRVOptionModal/LegacyGettingRVOptionModal';
import DeliveryFields from './DeliveryFields';
import { DeliveryFooterMessage } from './DeliveryFooterMessage';

const getDefaultValues = ({
  address,
  estimatedDistance,
  location,
  stationary,
}: {
  address?: string;
  estimatedDistance?: number;
  location?: IQuoteDeliveryLocation;
  stationary?: boolean;
}) => {
  if (!address) {
    return {
      [EDeliveryFields.DELIVERY_ADDRESS]: '',
      [EDeliveryFields.DELIVERY_LOCATION]: '',
      [EDeliveryFields.DELIVERY_DISTANCE]: '',
      [EDeliveryFields.DELIVERY_STATIONARY]: EDeliveryOption.MOVING,
      [EDeliveryFields.VOID_INSURANCE]: false,
    };
  }

  return {
    [EDeliveryFields.DELIVERY_ADDRESS]: address,
    [EDeliveryFields.DELIVERY_DISTANCE]: estimatedDistance?.toString(),
    [EDeliveryFields.DELIVERY_LOCATION]: JSON.stringify(location),
    [EDeliveryFields.DELIVERY_VALID]: 'true',
    [EDeliveryFields.DELIVERY_STATIONARY]: stationary
      ? EDeliveryOption.STATIONARY
      : EDeliveryOption.MOVING,
    [EDeliveryFields.VOID_INSURANCE]: stationary,
  };
};

type IDeliveryModalElement = React.HTMLAttributes<HTMLElement>;

interface IProps {
  deliveryAddress?: string;
  deliveryCost?: string;
  deliveryCostPerMile: string | undefined;
  deliveryRadius: {
    radius: number;
    unit: string;
  };
  deliveryLocation?: IQuoteDeliveryLocation;
  deliveryStationary?: boolean;
  distanceFromLocation?: number;
  error?: React.ReactNode;
  loading?: boolean;
  listingLocation?: ILocation;
  onClose: () => void;
  onSelectAddress: (value?: IQuoteDeliveryLocation) => void;
  onDeliverySubmit: (data: TDeliveryFields, isStationary: boolean) => void;
  onPickupSubmit: () => void;
  /** confirm delivery address submission */
  onAddressInputChange?: (resetDeliveryEstimate?: boolean) => void;
  deliveryType: EDeliveryOption;
  onChangeOption: (type: EDeliveryOption) => void;
  open: boolean;
  stationary?: boolean;
  isProposalMode?: boolean;
  deliveryModalTitle?: string;
  isSubmitting?: boolean;
  buttonLabel?: string;
  hideFooter?: boolean;
  isNegotiating?: boolean;
  deliveryFeeType?: DeliveryProposalFeeType;
}

const DeliveryModalLegacy: React.FC<IProps & IDeliveryModalElement> = ({
  deliveryType,
  onChangeOption,
  deliveryAddress,
  deliveryCost,
  deliveryLocation,
  deliveryRadius,
  deliveryStationary,
  distanceFromLocation,
  error,
  loading = false,
  listingLocation,
  onClose,
  onSelectAddress,
  onAddressInputChange,
  deliveryCostPerMile,
  onDeliverySubmit,
  onPickupSubmit,
  open,
  isProposalMode = false,
  deliveryModalTitle,
  isSubmitting,
  hideFooter = false,
  isNegotiating = false,
  deliveryFeeType,
}) => {
  const meetsMinDeliveryCostReq = useSelector(getMeetsMinDeliveryCostReq);

  const intl = useIntl();
  const form = useForm<TDeliveryFields>({
    mode: 'onSubmit',
    defaultValues: getDefaultValues({
      address: deliveryAddress,
      estimatedDistance: distanceFromLocation,
      stationary: deliveryStationary,
      location: deliveryLocation,
    }),
  });

  const [showDeliveryEstimate, setShowDeliveryEstimate] = useState<boolean>(true);
  const distanceUnit = deliveryRadius && mapPluralUnitToSingular(deliveryRadius.unit);
  const [selectedAddress, setSelectedAddress] = useState<IQuoteDeliveryLocation | undefined>();
  // an error when no address is selected from autocomplete
  const [missingAddressError, setMissingAddressError] = useState(false);
  // tracks whether delivery address has changed when modal is opened
  const [hasChanged, setHasChanged] = useState(false);
  const hasAddress = Boolean(deliveryAddress || selectedAddress);

  const validAddressError = intl.formatMessage({
    defaultMessage: `Please select from one of the delivery options.`,
    id: '50Yx2I',
    description: 'UI > Delivery > Delivery option error',
  });

  useEffect(() => {
    form.setValue(EDeliveryFields.DELIVERY_ADDRESS, deliveryAddress || '');
    onAddressInputChange?.(true);
    setSelectedAddress(undefined);
    setShowDeliveryEstimate(true);
    setMissingAddressError(false);
    setHasChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const parsedDeliveryRadius = formatMeasurement(deliveryRadius.radius || 0, {
    unit: distanceUnit || 'mile',
    long: true,
  });

  const isPickUpSelected = deliveryType === EDeliveryOption.PICKUP;

  const footerMessages = {
    default: intl.formatMessage({
      defaultMessage: `You'll receive the exact vehicle location after your request is confirmed. `,
      id: '1r6sFK',
    }),
    doesNotMeetMinDeliveryCostReq: intl.formatMessage(
      { defaultMessage: `The host has set a minimum delivery fee of {deliveryCost}`, id: 'pKbBn1' },
      { deliveryCost },
    ),
    deliveryDistance:
      distanceFromLocation &&
      intl.formatMessage(
        {
          defaultMessage: 'Delivery address is approx. {distance} away from the RV.',
          id: 'izxhGM',
        },
        {
          distance: formatMeasurement(distanceFromLocation, { unit: distanceUnit, long: true }),
        },
      ),
    withDeliveryCost: intl.formatMessage(
      {
        defaultMessage: 'Your delivery fee is {deliveryCost}',
        id: 'hHWfB7',
      },
      {
        deliveryCost,
      },
    ),
    withoutDeliveryCost: intl.formatMessage(
      {
        defaultMessage: ` Delivery fee will be calculated once you enter the delivery address. The host has a delivery radius of {parsedDeliveryRadius}. `,
        id: 'sshPTM',
      },
      {
        parsedDeliveryRadius,
      },
    ),
    isMakingPickUpProposal: intl.formatMessage({
      defaultMessage: `You'll get a refund for any delivery fee already paid if the host approves this request.`,
      id: 'mePbkz',
    }),
    isMakingPreApprovalPickUpProposal: intl.formatMessage({
      defaultMessage: `We will remove any delivery fee from your pending booking total.`,
      id: 'h7M6LX',
    }),
    isMakingDeliveryProposal: intl.formatMessage({
      defaultMessage: `You'll see the delivery cost once you enter an address.`,
      id: '2L1wPo',
    }),
    deliveryDistanceProposal:
      distanceFromLocation &&
      intl.formatMessage(
        {
          defaultMessage: 'Distance from RV location: {distance}',
          id: 'UH79bB',
        },
        {
          distance: formatMeasurement(distanceFromLocation, { unit: distanceUnit, long: true }),
        },
      ),
    deliveryDistanceProposalFee: intl.formatMessage(
      { defaultMessage: `Delivery fee: {deliveryCost}`, id: 'WodGIp' },
      { deliveryCost },
    ),
    deliveryDistanceProposalCharge: intl.formatMessage({
      defaultMessage: `Charged to your original payment method once the host approves your request. `,
      id: 'tW+j5m',
    }),
    insuranceFeeChange: intl.formatMessage({
      defaultMessage: `Insurance fees adjusted. `,
      id: '8cW9QZ',
    }),
    deliveryFeeChange: intl.formatMessage({
      defaultMessage: `Delivery fees adjusted. `,
      id: 'E5AI6/',
    }),
  };

  const getFooterMessage = () => {
    if (isProposalMode) {
      if (isPickUpSelected && !hideFooter) {
        if (isNegotiating) {
          return footerMessages.isMakingPreApprovalPickUpProposal;
        }

        return footerMessages.isMakingPickUpProposal;
      }

      if (!deliveryCost && !distanceFromLocation && !isPickUpSelected) {
        return footerMessages.isMakingDeliveryProposal;
      }

      if (deliveryCost && distanceFromLocation && !isPickUpSelected) {
        let subMessage = '';
        if (isNegotiating) {
          subMessage =
            deliveryFeeType === DeliveryProposalFeeType.INSURANCE_ONLY
              ? footerMessages.insuranceFeeChange
              : footerMessages.deliveryFeeChange;
        } else {
          subMessage = footerMessages.deliveryDistanceProposalCharge;
        }

        return (
          <>
            <span className="text-base font-bold text-gray-900">
              {footerMessages.deliveryDistanceProposalFee}
            </span>
            <br />
            <span>{subMessage}</span>
          </>
        );
      }
    }

    if (!deliveryCost && !distanceFromLocation && !isPickUpSelected)
      return footerMessages.withoutDeliveryCost;

    if (deliveryCost && distanceFromLocation && !isPickUpSelected)
      return (
        <>
          {footerMessages.deliveryDistance}
          <br />
          <span className="text-gray-800">
            {meetsMinDeliveryCostReq
              ? footerMessages.withDeliveryCost
              : footerMessages.doesNotMeetMinDeliveryCostReq}
          </span>
        </>
      );

    return footerMessages.default;
  };

  const footerMessage = getFooterMessage();
  const errorMessage = error || (missingAddressError ? validAddressError : '');

  // hide modal submit button on trip details page
  // when no address is entered or when there's an error
  const hideFooterButton =
    isProposalMode && !isPickUpSelected && (Boolean(errorMessage) || !selectedAddress);

  useEffect(() => {
    form.setValue(EDeliveryFields.DELIVERY_VALID, String(!error && !loading));
  }, [loading, error, form]);

  useEffect(() => {
    form.setValue(EDeliveryFields.DELIVERY_DISTANCE, String(distanceFromLocation));
  }, [distanceFromLocation, form]);

  useEffect(() => {
    if (!form.getValues(EDeliveryFields.DELIVERY_LOCATION) && deliveryLocation) {
      form.setValue(EDeliveryFields.DELIVERY_LOCATION, JSON.stringify(deliveryLocation));
    }
  }, [deliveryLocation, form]);

  useEffect(() => {
    if (!form.getValues(EDeliveryFields.DELIVERY_ADDRESS) && deliveryAddress && !open) {
      form.setValue(EDeliveryFields.DELIVERY_ADDRESS, deliveryAddress);
    }
  }, [deliveryAddress, form, open]);

  const handlePickupSubmit = () => {
    onPickupSubmit();
    onClose();
  };

  const handleDeliverySubmit = (data: TDeliveryFields) => {
    // prevents submission when address is modified
    if (!hasAddress && hasChanged) {
      setMissingAddressError(true);
      return;
    }

    setMissingAddressError(false);
    onClose();
    onDeliverySubmit(data, deliveryType === EDeliveryOption.STATIONARY);
  };

  const handleSubmit = () => {
    if (isPickUpSelected) {
      return handlePickupSubmit();
    }

    return form.handleSubmit(handleDeliverySubmit)();
  };

  const handleSelectAddress = useCallback(
    (option: IAutocompleteOption) => {
      const formattedAddress = itemizeAddress(option.value);
      form.setValue(EDeliveryFields.DELIVERY_ADDRESS, option.label);
      form.setValue(EDeliveryFields.DELIVERY_LOCATION, JSON.stringify(formattedAddress));
      form.setValue(EDeliveryFields.QUOTE_DELIVERY_CAMPGROUND_ID, 0);
      setHasChanged(true);
      setMissingAddressError(false);

      if (!option.value) {
        setSelectedAddress(undefined);
        return;
      }
      onAddressInputChange?.(true);
      setSelectedAddress(formattedAddress);
      onSelectAddress(formattedAddress);
      setShowDeliveryEstimate(true);
    },
    [form, onAddressInputChange, onSelectAddress],
  );

  const handleSelectStationary = useCallback(
    (value: EDeliveryOption) => {
      if (!isSubmitting) {
        onChangeOption(value);
      }
    },
    [onChangeOption, isSubmitting],
  );

  const handleAddressInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newState = e.currentTarget.value;
      form.setValue(EDeliveryFields.DELIVERY_ADDRESS, newState);
      onAddressInputChange?.();
      // reset address selection, and remove error
      setHasChanged(true);
      setShowDeliveryEstimate(false);
      setMissingAddressError(false);
      setSelectedAddress(undefined);
      form.setValue(EDeliveryFields.QUOTE_DELIVERY_CAMPGROUND_ID, 0);
    },
    [form, onAddressInputChange],
  );

  if (!listingLocation) {
    return null;
  }

  return (
    <LegacyGettingRVOptionModal
      isOpen={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      isLoading={isSubmitting || loading}
      footerMessage={
        !!showDeliveryEstimate && (
          <DeliveryFooterMessage message={footerMessage} loading={loading} error={errorMessage} />
        )
      }
      deliveryModalTitle={deliveryModalTitle}
      isDisabled={hideFooterButton}>
      <form
        id="delivery-form"
        data-testid="delivery-form"
        /*onSubmit={form.handleSubmit(handleSubmit)}*/
      >
        <DeliveryFields
          form={form}
          deliveryCostPerMile={deliveryCostPerMile}
          location={listingLocation}
          deliveryType={deliveryType}
          onChangeOption={onChangeOption}
          onInputChange={handleAddressInputChange}
          onSelectAddress={handleSelectAddress}
          onSelectStationary={handleSelectStationary}
        />
        {isProposalMode && distanceFromLocation && !isPickUpSelected && (
          <div className="mt-4 text-gray-500 autoType200">
            {footerMessages.deliveryDistanceProposal}
          </div>
        )}
      </form>
    </LegacyGettingRVOptionModal>
  );
};

export default DeliveryModalLegacy;
