import dayjs from 'dayjs';

import { IBookingGuest } from '@/services/types/booking/details';

export const isUnder25 = (passenger: IBookingGuest) => {
  const birthDate = dayjs(passenger.birthdate);
  const today = dayjs();
  const age = today.diff(birthDate, 'year');

  return age < 25;
};
