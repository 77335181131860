import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { errorTemplate } from '@/components/switchback/AddressInput/AddressInput';
import Checkbox from '@/components/switchback/Checkbox/Checkbox';
import Text from '@/components/switchback/Text/Text';
import {
  EDeliveryFields,
  EDeliveryOption,
  TDeliveryFields,
} from '@/services/types/core/delivery.types';

import { LearnMore } from './LearnMore';

export const VoidInsuranceConfirmation = ({ form }: { form: UseFormReturn<TDeliveryFields> }) => {
  const intl = useIntl();

  const watchStationary = form.watch(EDeliveryFields.DELIVERY_STATIONARY, EDeliveryOption.MOVING);

  return (
    <div className="mt-4 ml-8 text-gray-500 autoType300">
      <Checkbox
        id={EDeliveryFields.VOID_INSURANCE}
        hasError={!!form.formState.errors[EDeliveryFields.VOID_INSURANCE]}
        {...form?.register?.(EDeliveryFields.VOID_INSURANCE, {
          required: {
            value: watchStationary === EDeliveryOption.STATIONARY,
            message: intl.formatMessage({
              defaultMessage: 'This field is required.',
              id: '6B5Jtu',
            }),
          },
        })}>
        <Text type="paragraph" className="text-sm text-gray-900">
          {intl.formatMessage({
            defaultMessage: 'I acknowledge that moving a stationary rental voids my insurance.',
            id: 'VJHMXk',
          })}
        </Text>
      </Checkbox>
      {errorTemplate(form, EDeliveryFields.VOID_INSURANCE)}
      <LearnMore />
    </div>
  );
};
