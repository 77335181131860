import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Heading from '@/components/switchback/Heading/Heading';
import RadioBox from '@/components/switchback/RadioBox/RadioBox';
import Text from '@/components/switchback/Text/Text';
import { getDeliveryRadius } from '@/redux/selectors/listing/delivery';
import { EDeliveryOption } from '@/services/types/core/delivery.types';

enum EDeliveryTypes {
  DELIVERY = 'delivery',
  PICKUP = 'pickup',
}

// Map EDeliveryOption to EDeliveryTypes
const mapDeliveryOptionToType = (option: EDeliveryOption): EDeliveryTypes | null => {
  if (option === EDeliveryOption.PICKUP) return EDeliveryTypes.PICKUP;
  if (option === EDeliveryOption.STATIONARY) return EDeliveryTypes.DELIVERY;
  if (option === EDeliveryOption.MOVING) return EDeliveryTypes.DELIVERY;
  if (option === EDeliveryOption.RV_STAY) return EDeliveryTypes.DELIVERY;
  return null;
};

// Map EDeliveryTypes to EDeliveryOption
const mapTypeToDeliveryOption = (type: EDeliveryTypes): EDeliveryOption => {
  if (type === EDeliveryTypes.PICKUP) return EDeliveryOption.PICKUP;
  // Default to STATIONARY for delivery type
  return EDeliveryOption.STATIONARY;
};

type IDeliveryOptionsElement = React.HTMLAttributes<HTMLElement>;

const options: { id: string; value: EDeliveryTypes }[] = [
  { id: 'pickup', value: EDeliveryTypes.PICKUP },
  { id: 'delivery', value: EDeliveryTypes.DELIVERY },
];

interface IProps extends IDeliveryOptionsElement {
  deliveryType: EDeliveryOption;
  deliveryCostPerMile: string | undefined;
  onChangeOption: (type: EDeliveryOption) => void;
  onSelectPickup?: (isPickup: boolean) => void;
  setIsOpen?: (isOpen: boolean) => void;
}

const DeliveryModalOptions: React.FC<IProps> = ({
  deliveryType,
  onChangeOption,
  deliveryCostPerMile,
}) => {
  const deliveryRadius = useSelector(getDeliveryRadius);
  const intl = useIntl();

  const handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value as EDeliveryTypes;
    const deliveryOption = mapTypeToDeliveryOption(value);

    // Call the parent's onChangeOption with the mapped delivery option
    onChangeOption(deliveryOption);
  };

  const deliveryOptionsMap = {
    [EDeliveryTypes.PICKUP]: {
      title: intl.formatMessage({
        defaultMessage: 'Pick up - Free',
        id: 'uC0jCq',
      }),
      description: intl.formatMessage({
        defaultMessage: "I'll pick up my RV at the vehicle owners set location.",
        id: 'qVqTsg',
      }),
    },
    [EDeliveryTypes.DELIVERY]: {
      title: intl.formatMessage(
        { defaultMessage: 'Delivery - {deliveryCostPerMile} per {unit}', id: 'K7C/Sx' },
        { deliveryCostPerMile, unit: deliveryRadius?.unit || 'mile' },
      ),
      description: intl.formatMessage(
        {
          defaultMessage: 'This host delivers to locations within a {radius} {unit} radius.',
          id: 'oAMGhA',
        },
        { radius: deliveryRadius?.radius, unit: deliveryRadius?.unit || 'mile' },
      ),
    },
  };

  return (
    <div className="pb-4 mt-4">
      <fieldset className="grid gap-x-default gap-y-4 md:grid-flow-col">
        {options.map(option => {
          const { id, value } = option;
          const isChecked = mapDeliveryOptionToType(deliveryType) === value;
          const { title, description } = deliveryOptionsMap[value];

          return (
            <div className="block" key={id}>
              <RadioBox
                name="delivery"
                labelClassName={`h-full p-2 md:p-4`}
                id={`radio-${id}`}
                label={
                  <Heading level={6} className="text-base">
                    {title}
                  </Heading>
                }
                value={value}
                onChange={handleChangeOption}
                checked={isChecked}
                data-testid={`${id}-btn`}>
                <Text type="paragraph" className="text-sm">
                  {description}
                </Text>
              </RadioBox>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export default DeliveryModalOptions;
