import { Button, EButtonColorVariant, ELoaderSize, Loader } from '@outdoorsyco/bonfire';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { defaultLocale } from '@/config/locales';
import { ESearchFilters } from '@/constants/searchFilters';
import { EFilterMethod, setFormFilter } from '@/redux/modules/searchForm';
import { getCurrency } from '@/redux/selectors/currency';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { getSearchResultsMeta } from '@/redux/selectors/search/meta';
import { isHisotgramLoading } from '@/redux/selectors/search/pricing';
import { getSearchFormPriceFilter } from '@/redux/selectors/search/searchForm';
import { clamp } from '@/utility/numbers';

import PriceFilter from './PriceFilter';

interface IPriceFilterContainerProps {
  show: boolean;
  showConfirm?: boolean;
  onSubmit?: () => void;
}

export const PriceFilterContainer = ({
  show,
  showConfirm = true,
  onSubmit,
}: IPriceFilterContainerProps) => {
  const intl = useIntl();
  const rawDispatch = useDispatch();
  const dispatch = debounce(rawDispatch, 32);
  const {
    [ESearchFilters.PRICE_MIN]: minPriceFromStore,
    [ESearchFilters.PRICE_MAX]: maxPriceFromStore,
  } = useSelector(getSearchFormPriceFilter);
  const {
    priceHistogram: { data: histogramValues, maxValue },
  } = useSelector(getSearchResultsMeta);

  const isHistogramLoading = useSelector(isHisotgramLoading);

  const [prices, setPrices] = useState<[number, number]>([0, maxValue]);
  const currency = useSelector(getCurrency) || defaultLocale.base_currency;
  const { [ESearchFilters.PRICE_MIN]: pMin, [ESearchFilters.PRICE_MAX]: pMax } =
    useSelector(getQueryParams);

  const handlePriceChange = (min: number, max: number) => {
    const targetMin = clamp(min, 0, maxValue);
    const targetMax = clamp(max, 100, maxValue);

    setPrices([targetMin, targetMax]);
  };

  const handlePriceChangeCommitted = (min: number, max: number, fromInput = false) => {
    const targetMin = clamp(min, 0, maxValue);
    const targetMax = clamp(max, 100, maxValue);

    dispatch(
      setFormFilter({
        filters: {
          [ESearchFilters.PRICE_MIN]: targetMin === 0 ? undefined : targetMin,
          [ESearchFilters.PRICE_MAX]: targetMax === maxValue ? undefined : targetMax,
        },
        filterMethod: fromInput ? EFilterMethod.INPUT : EFilterMethod.SLIDER,
      }),
    );
  };

  useEffect(() => {
    // when both are undefined, assume a reset
    if (typeof minPriceFromStore === 'undefined' && typeof maxPriceFromStore === 'undefined') {
      setPrices([0, maxValue]);
    }
  }, [minPriceFromStore, maxPriceFromStore, maxValue]);

  useEffect(() => {
    const targetMin = clamp(Number(pMin || 0), 0, maxValue);
    const targetMax = clamp(Number(pMax || maxValue), 100, maxValue);

    dispatch(
      setFormFilter(
        {
          filters: {
            ...(pMin && {
              [ESearchFilters.PRICE_MIN]: targetMin,
            }),
            ...(pMax && {
              [ESearchFilters.PRICE_MAX]: targetMax,
            }),
          },
          filterMethod: null,
        },
        true,
      ),
    );
    setPrices([targetMin, targetMax]);

    // Run only on mount to sync state with query parameters.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [histogramValues]);

  return (
    <div className="pb-8 md:pb-0">
      {isHistogramLoading ? (
        <Loader className="flex items-center justify-center !w-full" size={ELoaderSize.Large} />
      ) : (
        <>
          <PriceFilter
            minValue={0}
            maxValue={maxValue}
            currency={currency}
            histogramValues={histogramValues}
            onChange={handlePriceChange}
            onChangeCommitted={handlePriceChangeCommitted}
            defaultValue={prices}
            show={show}
          />
          {showConfirm && (
            <Button
              className="mt-8 md:hidden"
              variant={EButtonColorVariant.Primary}
              label={intl.formatMessage({ defaultMessage: 'Confirm', id: 'N2IrpM' })}
              fullWidth
              onClick={onSubmit}
            />
          )}
        </>
      )}
    </div>
  );
};
