import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { BillExpandedDeliveryButtons } from '@/components/route/listing/BillSectionSelection/BillSelectionStep/BillExpandedDeliveryButtons/BillExpandedDeliveryButtons';
import Text from '@/components/switchback/Text/Text';
import { BillModuleStepButton } from '@/components/ui/BillModule/BillModuleStepButton/BillModuleStepButton';
import { ButtonFilledState } from '@/components/ui/BillModule/ButtonFilledState/ButtonFilledState';
import { DeliveryContainer } from '@/components/ui/DeliveryModal/DeliveryContainer';
import { useDeliveryModalContext } from '@/context/DeliveryModalContext';
import {
  getBookingOrQuoteDeliverableCampground,
  isOutdoorsyStayBookingOrQuote,
} from '@/redux/selectors/bill/data';
import { getQuoteData } from '@/redux/selectors/quote';
import { useExperimentIsEnabled } from '@/services/experiments';
import { OptimizelyFlags } from '@/services/experiments/flags';
import { EDeliveryOption, TDeliveryFields } from '@/services/types/core/delivery.types';
import { IQuoteDeliveryLocation } from '@/services/types/quote/IDeliveryLocation';
import { ILocation } from '@/services/types/search/rentals/id';

interface IProps {
  deliveryAddress?: string;
  deliveryLocation?: IQuoteDeliveryLocation;
  deliveryType: EDeliveryOption;
  hasDelivery?: boolean;
  onPickupSubmit: () => void;
  onDeliverySubmit: (data: TDeliveryFields, isStationary: boolean) => void;
  listingLocation?: ILocation;
  isCompleted?: boolean;
}

export const formatListingLocation = (listingLocation?: ILocation) =>
  listingLocation && `${listingLocation.city}, ${listingLocation.state || listingLocation.county}`;

export const BillModuleDelivery: React.FC<IProps> = ({
  deliveryAddress,
  deliveryLocation,
  deliveryType,
  hasDelivery,
  onPickupSubmit,
  onDeliverySubmit,
  listingLocation,
  isCompleted = true,
}) => {
  const { isDeliveryModalOpen, openDeliveryModal, closeDeliveryModal, isSetupDelivery } =
    useDeliveryModalContext();
  const deliveryRevertDecision = useExperimentIsEnabled(
    OptimizelyFlags.REVERT_SETUP_DELIVERY_CHANGE,
  );
  const quote = useSelector(getQuoteData);
  const isOutdoorsyStay = useSelector(isOutdoorsyStayBookingOrQuote);
  const campground = useSelector(getBookingOrQuoteDeliverableCampground);
  const campgroundLocation = quote?.siblings?.rental_campsite?.rental_summary?.location;
  const campgroundName = campground?.name;
  const campgroundAvatar = campground?.primary_image_url;
  const [deliveryOption, setDeliveryOption] = useState<EDeliveryOption>(deliveryType);
  const handleSetDeliveryType = useCallback((type: EDeliveryOption) => {
    setDeliveryOption(type);
  }, []);

  const handleClose = useCallback(() => {
    closeDeliveryModal();
    setDeliveryOption(deliveryType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryType]);

  useEffect(() => {
    if (isSetupDelivery) {
      setDeliveryOption(EDeliveryOption.STATIONARY);
    } else {
      setDeliveryOption(deliveryType);
    }
  }, [deliveryType, isSetupDelivery]);

  const handleOpenExpandedDeliveryModal = useCallback((type: EDeliveryOption) => {
    setDeliveryOption(type);
    openDeliveryModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const intl = useIntl();
  const deliveryButtonLabel =
    deliveryType === EDeliveryOption.PICKUP
      ? intl.formatMessage({ defaultMessage: 'Pick it up at', id: 'W6hgDO' })
      : deliveryType === EDeliveryOption.MOVING || deliveryRevertDecision
        ? intl.formatMessage({ defaultMessage: 'Delivery to', id: 'Mp5EhP' })
        : intl.formatMessage({ defaultMessage: 'Set up at', id: '/ZGP6R' });

  const inexactLocationLabel = intl.formatMessage({
    defaultMessage: 'Exact location provided after booking',
    id: 'UikgDr',
  });

  const filledDeliveryStepButton = isOutdoorsyStay ? (
    <ButtonFilledState
      text={deliveryButtonLabel}
      showODStayDeliveryButton
      listingName={campgroundName}
      location={campgroundLocation}
      onClick={openDeliveryModal}
      listingAvatar={campgroundAvatar}
    />
  ) : (
    <BillModuleStepButton
      outlined={false}
      text={deliveryButtonLabel}
      linkText={
        deliveryType === EDeliveryOption.PICKUP
          ? formatListingLocation(listingLocation)
          : deliveryAddress || (
              <FormattedMessage
                defaultMessage="Change address"
                id="NUyJTD"
                description="UI > BillModule > BillModuleDelivery"
              />
            )
      }
      onClick={openDeliveryModal}
    />
  );

  const renderDeliveryStepButtons = isCompleted ? (
    <>
      {filledDeliveryStepButton}
      {deliveryType === EDeliveryOption.PICKUP && (
        <Text className="italic text-gray-500 whitespace-pre-line autoType300" type="inline">
          {inexactLocationLabel}
        </Text>
      )}
    </>
  ) : (
    <BillExpandedDeliveryButtons
      listingLocation={listingLocation}
      onPickupSubmit={onPickupSubmit}
      handleOpenDeliveryModal={handleOpenExpandedDeliveryModal}
    />
  );

  return hasDelivery ? (
    <>
      {renderDeliveryStepButtons}
      <DeliveryContainer
        open={isDeliveryModalOpen}
        onClose={handleClose}
        onDeliverySubmit={onDeliverySubmit}
        onPickupSubmit={onPickupSubmit}
        deliveryType={deliveryOption}
        listingLocation={listingLocation}
        deliveryAddress={deliveryAddress}
        deliveryLocation={deliveryLocation}
        onChangeDeliveryType={handleSetDeliveryType}
      />
    </>
  ) : (
    <ButtonFilledState
      text={<FormattedMessage defaultMessage="Pick it up at" id="W6hgDO" />}
      linkText={formatListingLocation(listingLocation)}
      omitCaret
    />
  );
};
