import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import AddressInput from '@/components/switchback/AddressInput/AddressInput';
import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import Heading from '@/components/switchback/Heading/Heading';
import {
  EDeliveryFields,
  EDeliveryOption,
  TDeliveryFields,
} from '@/services/types/core/delivery.types';
import { ILocation } from '@/services/types/search/rentals/id';

import { AfterDeliveryOptions } from './AfterDeliveryOptions';
import DeliveryModalOptions from './DeliveryModalOptions';

type IDeliveryFieldsElement = React.HTMLAttributes<HTMLElement>;

interface IProps {
  form: UseFormReturn<TDeliveryFields>;
  location: ILocation;
  deliveryCostPerMile: string | undefined;
  deliveryType: EDeliveryOption;
  onChangeOption: (type: EDeliveryOption) => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectAddress?: (value: IAutocompleteOption) => void;
  onSelectStationary?: (value: EDeliveryOption) => void;
}

const DeliveryFields: React.FC<IProps & IDeliveryFieldsElement> = ({
  form,
  location,
  onInputChange,
  onSelectAddress,
  onSelectStationary,
  deliveryCostPerMile,
  deliveryType,
  onChangeOption,
}) => {
  const intl = useIntl();
  const addressValue = form.watch(EDeliveryFields.DELIVERY_ADDRESS) || '';

  const watchStationary = form.watch<EDeliveryFields>(
    EDeliveryFields.DELIVERY_STATIONARY,
    EDeliveryOption.MOVING,
  ) as EDeliveryOption;

  useEffect(() => {
    onSelectStationary?.(watchStationary);
  }, [watchStationary, onSelectStationary]);

  return (
    <>
      <DeliveryModalOptions
        deliveryCostPerMile={deliveryCostPerMile}
        deliveryType={deliveryType}
        onChangeOption={onChangeOption}
      />
      {(deliveryType === EDeliveryOption.MOVING || deliveryType === EDeliveryOption.STATIONARY) && (
        <>
          <AfterDeliveryOptions form={form} watchStationary={watchStationary} />
          <Heading level={3} className="mt-8 mb-4 autoType600 highlight">
            {intl.formatMessage({
              defaultMessage: 'Enter delivery address',
              id: 'lQZ2Ad',
            })}
          </Heading>
          <div className="mt-2 mb-3 md:mb-2">
            <AddressInput
              form={form}
              required={
                deliveryType === EDeliveryOption.MOVING ||
                deliveryType === EDeliveryOption.STATIONARY
              }
              addressValue={addressValue}
              name={EDeliveryFields.DELIVERY_ADDRESS}
              onChangeAddress={onInputChange}
              onSelectAddress={onSelectAddress}
              placesOption={{
                location: [location?.lng || 0, location?.lat || 0],
                types: 'address,region,place,district,locality,poi',
              }}
              popupOptions={{
                disablePortal: false,
                anchorReference: 'anchorEl',
                disableScrollLock: true,
              }}
              label={intl.formatMessage({
                defaultMessage: 'Delivery location',
                id: 'Flx2cd',
              })}
              popupClassName="p-8 shadow-100 rounded-box !z-[1450] mt-1"
              skipFocusListener
            />
          </div>
        </>
      )}
    </>
  );
};

export default DeliveryFields;
