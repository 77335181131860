import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Heading from '@/components/switchback/Heading/Heading';
import Radio from '@/components/switchback/Radio/Radio';
import Text from '@/components/switchback/Text/Text';
import {
  EDeliveryFields,
  EDeliveryOption,
  TDeliveryFields,
} from '@/services/types/core/delivery.types';

import css from './AfterDeliveryOptions.module.css';
import { VoidInsuranceConfirmation } from './VoidInsuranceConfirmation';

interface IOptionCaptionProps {
  caption: string;
}

const OptionCaption: React.FC<IOptionCaptionProps> = ({ caption }) => {
  return (
    <Text
      type="paragraph"
      className="mt-1 ml-8 text-sm font-normal text-gray-500 cursor-pointer md:text-base">
      {caption}
    </Text>
  );
};

export const AfterDeliveryOptions = ({
  form,
  watchStationary,
}: {
  form: UseFormReturn<TDeliveryFields>;
  watchStationary: string;
}) => {
  const intl = useIntl();

  const afterDeliveryInsuranceOptions = {
    [EDeliveryOption.MOVING]: {
      title: intl.formatMessage({
        defaultMessage: 'Yes, we’re hitting the road!',
        id: 'aEjIfb',
      }),
      description: intl.formatMessage({
        defaultMessage: 'Excellent, the host will deliver the rig and have it ready for your trip.',
        id: '82+wZU',
      }),
    },
    [EDeliveryOption.STATIONARY]: {
      title: intl.formatMessage({
        defaultMessage: 'No, we’re staying in one place.',
        id: 'WQCGfM',
      }),
      description: intl.formatMessage({
        defaultMessage:
          'Perfect, the host will deliver the rig and we’ll discount your insurance since you won’t be moving it.',
        id: 'FpYonj',
      }),
    },
  };
  return (
    <div>
      <Heading level={3} className="my-2 autoType600 highlight">
        {intl.formatMessage({
          defaultMessage: 'Are you moving the vehicle after it’s delivered?',
          id: 'iiLsfG',
        })}
      </Heading>
      <fieldset className="lg:w-full lg:block">
        {Object.entries(afterDeliveryInsuranceOptions).map(([key, option]) => {
          const isChecked = watchStationary === key;
          return (
            <div className={css.option} key={option.title}>
              <Radio
                id={`radio-${option.title}`}
                data-testid={`radio-${option.title}`}
                checked={isChecked}
                value={key}
                {...form?.register?.(EDeliveryFields.DELIVERY_STATIONARY, {
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      defaultMessage: 'You are required to select an option.',
                      id: 'DaZcPq',
                    }),
                  },
                })}>
                <span
                  className={`highlight autoType600 font-normal ${
                    isChecked ? 'text-gray-900' : 'text-gray-500'
                  }`}>
                  {option.title}
                </span>
              </Radio>
              {isChecked && <OptionCaption caption={option.description} />}
            </div>
          );
        })}
        {watchStationary === EDeliveryOption.STATIONARY && (
          <VoidInsuranceConfirmation form={form} />
        )}
      </fieldset>
    </div>
  );
};
