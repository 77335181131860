import { GeneralCaretDownIcon } from '@outdoorsyco/bonfire';
import clsx from 'clsx';
import React from 'react';

import Text from '@/components/switchback/Text/Text';
import { STRINGS } from '@/constants/strings';

const DropdownButton: React.FC<{
  onClick: () => void;
  count?: number;
  title?: React.ReactNode;
  selected?: boolean;
  active?: boolean;
  setFieldElement?: (element: HTMLElement | null) => void;
  customIcon?: React.ReactNode;
}> = ({ onClick, selected, active, setFieldElement, customIcon, count, title }) => {
  const rootClassName = clsx(
    'relative flex items-center px-4 border border-gray-310 rounded-full whitespace-nowrap h-[2.125rem] md:h-10',
    {
      'bg-gray-800 border-gray-800': active || !!count,
    },
  );
  const textClassName = clsx(
    'font-medium autoType500 text-gray-900 flex items-center justify-center',
    {
      '!text-white bg-gray-800': active || !!count,
    },
  );
  const iconClassName = clsx('ml-1 transition-all duration-300 text-sm', {
    '!rotate-180': selected,
  });

  return (
    <button ref={selected ? setFieldElement : null} className={rootClassName} onClick={onClick}>
      <Text className={textClassName} type="inline">
        <span className="flex items-center justify-center">{title}</span>
        {!!count && `${STRINGS.NBSP}(${count})`}
        {/* {customIcon || <Icon width={10} className={iconClassName} name={CARET_LARGE} />} */}
        {customIcon || <GeneralCaretDownIcon className={iconClassName} />}
      </Text>
    </button>
  );
};

export default DropdownButton;
