import { ETextStyleVariant, Loader, Text } from '@outdoorsyco/bonfire';
import React from 'react';

import { ALERT_OCTAGON } from '@/components/switchback/Icon/assets';
import Notice, { NoticeType } from '@/components/switchback/Notice/Notice';

interface IDeliveryFooterMessageProps {
  message: React.ReactNode;
  loading: boolean;
  error: React.ReactNode;
}

export const DeliveryFooterMessage = ({ message, loading, error }: IDeliveryFooterMessageProps) => {
  if (loading) {
    return <Loader />;
  }
  if (error)
    return (
      <div className="text-red-800">
        <Notice icon={ALERT_OCTAGON} variant={NoticeType.critical}>
          <Text variant={ETextStyleVariant.SmallRegular}>{error}</Text>
        </Notice>
      </div>
    );

  if (message) {
    return (
      <Text variant={ETextStyleVariant.SmallRegular} className="mb-4 md:mb-0">
        {message}
      </Text>
    );
  }

  return null;
};
