import kebabCase from 'lodash/kebabCase';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { hasPressed } from '@/utility/accessibility';

import { IProps as IAutocompleteProps } from './Autocomplete';
import css from './AutocompleteOptions.module.css';

export interface IAutocompleteOption {
  label: string;
  // Setting value as any so parent components/containers can use any type for it
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  url?: string;
}

interface IProps extends Pick<IAutocompleteProps, 'options'> {
  optionTestId?: string;
  selectedSuggestionIndex?: number;
  onSelectOption?: (index: number) => void;
}

const AutocompleteOptions: React.FC<IProps> = ({
  options,
  optionTestId = 'autocomplete-option',
  selectedSuggestionIndex,
  onSelectOption,
}) => {
  const intl = useIntl();
  const [focusSuggestionIndex, setFocusSuggestionIndex] = useState(-1);
  const listRef = useRef<HTMLUListElement>(null);

  const handleSelectOption = useCallback(
    (index?: number) => {
      const suggestionIndex = index !== undefined ? index : focusSuggestionIndex || 0;

      if (suggestionIndex === -1) {
        return;
      }

      onSelectOption?.(suggestionIndex);
    },
    [focusSuggestionIndex, onSelectOption],
  );

  const handleKeyboardNavigation = useCallback(
    (e: KeyboardEvent) => {
      if (!options?.length) {
        return;
      }

      if (hasPressed(e).DOWN) {
        e.preventDefault();
        const isLastItem = focusSuggestionIndex === options.length - 1;
        setFocusSuggestionIndex(isLastItem ? 0 : focusSuggestionIndex + 1);
      }

      if (hasPressed(e).UP) {
        e.preventDefault();
        const isFirstItem = focusSuggestionIndex <= 0;
        setFocusSuggestionIndex(isFirstItem ? options.length - 1 : focusSuggestionIndex - 1);
      }

      if (hasPressed(e).HOME) {
        e.preventDefault();
        setFocusSuggestionIndex(0);
      }
      if (hasPressed(e).END) {
        e.preventDefault();
        setFocusSuggestionIndex(options.length - 1);
      }

      if (hasPressed(e).ENTER) {
        e.preventDefault();
        handleSelectOption();
      }
    },
    [options, focusSuggestionIndex, handleSelectOption],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardNavigation);

    return () => {
      window.removeEventListener('keydown', handleKeyboardNavigation);
    };
  }, [handleKeyboardNavigation]);

  const handleClickOption = (index: number) => () => {
    setFocusSuggestionIndex(index);
    onSelectOption?.(index);
  };

  useEffect(() => {
    if (focusSuggestionIndex === -1) {
      return;
    }

    (listRef.current?.childNodes[focusSuggestionIndex] as HTMLLIElement | undefined)?.focus();
  }, [focusSuggestionIndex]);

  if (!options?.length) {
    return null;
  }

  // Keyboard events are being handled out of this subcomponent
  /*  eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <div aria-live="polite">
      <span className="sr-only">
        {intl.formatMessage({
          defaultMessage: 'Arrow down for options or start typing to filter',
          id: 'GtAL6Q',
          description: "Screen readers' message for autocomplete",
        })}
      </span>
      <ul className="autoType300" role="listbox" ref={listRef}>
        {options.map((item, index) => (
          <li
            aria-selected={selectedSuggestionIndex === index}
            className={`${css.option} px-4 py-2 md:px-8 cursor-pointer`}
            aria-disabled="false"
            data-focus={focusSuggestionIndex === index}
            data-option-index="1"
            data-test-id={kebabCase(`${optionTestId} ${item.label}`)}
            id="autocomplete-option-1"
            key={index}
            role="option"
            tabIndex={-1}
            onClick={handleClickOption(index)}>
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
  /* eslint-enable jsx-a11y/click-events-have-key-events */
};

export default AutocompleteOptions;
