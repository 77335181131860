import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Heading from '@/components/switchback/Heading/Heading';
import { ICONS } from '@/components/switchback/Icon/Icon.constants';
import Notice, { NoticeType } from '@/components/switchback/Notice/Notice';
import Text from '@/components/switchback/Text/Text';
import { ESearchFilters } from '@/constants/searchFilters';
import { EFilterMethod, setFormFilter } from '@/redux/modules/searchForm';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { getSearchFormDeliveryFilter } from '@/redux/selectors/search/searchForm';
import { EDeliveryOption } from '@/services/types/core/delivery.types';

import InsuranceOptions from './InsuranceOptions';

// No props needed for this component
const LegacyDeliveryFilter: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationaryFromStore } = useSelector(
    getSearchFormDeliveryFilter,
  );
  const {
    [ESearchFilters.DELIVERY]: deliveryFromQuery,
    [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationaryFromQuery,
    [ESearchFilters.ADDRESS]: deliveryAddressFromQuery,
  } = useSelector(getQueryParams);

  const insuranceValue: string = useMemo(() => {
    if (deliveryFromQuery !== 'true' && !deliveryStationaryFromStore) {
      return '';
    }

    return deliveryStationaryFromStore || '';
  }, [deliveryFromQuery, deliveryStationaryFromStore]);

  const insuranceOptions = {
    [EDeliveryOption.MOVING]: {
      title: intl.formatMessage({
        defaultMessage: 'Bring it to me',
        id: 'ez1l3k',
        description:
          'Search DeliveryFilterContainer - title message for moving option on delivery filter',
      }),
      description: intl.formatMessage({
        defaultMessage:
          'You’re going places. Let the host drop off the vehicle at your house, an airport, or somewhere else, so you can hit the road from there.',
        id: 'D3DvS8',
        description:
          'Search DeliveryFilterContainer - description message for moving option on delivery filter',
      }),
    },
    [EDeliveryOption.STATIONARY]: {
      title: intl.formatMessage({
        defaultMessage: 'Set it up at my destination',
        id: 'YQXN0d',
        description:
          'Search DeliveryFilterContainer - title message for stationary option on delivery filter',
      }),
      description: intl.formatMessage({
        defaultMessage:
          'No driving. Just relaxing. The host will set up the vehicle at the campsite, RV park, or other location you’ve arranged. ',
        id: 'J3WJ4W',
        description:
          'Search DeliveryFilterContainer - description message for stationary option on delivery filter',
      }),
    },
  };

  //Set an initial value
  useEffect(() => {
    dispatch(
      setFormFilter(
        {
          filters: {
            ...(deliveryFromQuery && {
              [ESearchFilters.DELIVERY]: deliveryFromQuery === 'true',
            }),
            ...(deliveryFromQuery === 'true' &&
              deliveryAddressFromQuery && {
                [ESearchFilters.DELIVERY_ADDRESS]: deliveryAddressFromQuery as string,
              }),
            ...(deliveryStationaryFromQuery && {
              [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationaryFromQuery as string,
            }),
          },
          filterMethod: null,
        },
        true,
      ),
    );

    // Run only on mount to sync state with query parameters.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setFormFilter({
          filters: {
            [ESearchFilters.DELIVERY]: true,
            [ESearchFilters.DELIVERY_ADDRESS]: deliveryAddressFromQuery as string,
            [ESearchFilters.DELIVERY_STATIONARY]: e.target.value,
          },
          filterMethod: EFilterMethod.RADIO,
        }),
      );
    },
    [dispatch, deliveryAddressFromQuery],
  );

  const handleResetRadio = useCallback(() => {
    dispatch(
      setFormFilter({
        filters: {
          [ESearchFilters.DELIVERY]: false,
          [ESearchFilters.DELIVERY_ADDRESS]: deliveryAddressFromQuery as string,
          [ESearchFilters.DELIVERY_STATIONARY]: '',
        },
        filterMethod: EFilterMethod.RADIO,
      }),
    );
  }, [dispatch, deliveryAddressFromQuery]);
  return (
    <div className="md:w-136">
      <Heading
        className="text-gray-500 autoType400 md:highlight md:text-gray-900 md:mt-1"
        level={4}>
        {intl.formatMessage({
          defaultMessage: 'Search RVs with one of the following delivery options',
          id: 'ZKbZB6',
          description: 'Search DeliveryFilter Options - title',
        })}
      </Heading>
      <div className="mt-4 mb-6 grid gap-x-default gap-y-4 md:grid-flow-col">
        <InsuranceOptions
          insuranceValue={insuranceValue}
          onChange={handleSelectRadio}
          onReset={handleResetRadio}
          name="insurance-options"
          insuranceOptions={insuranceOptions}
        />
      </div>
      {insuranceValue && (
        <div className="mb-6">
          <Notice icon={ICONS.MAP} variant={NoticeType.notice} filled>
            <Text type="paragraph" className="text-sm text-gray-800 semiHighlight">
              {insuranceValue === 'moving'
                ? intl.formatMessage({
                    id: 'HuCaCI',
                    defaultMessage:
                      'Great, let’s find you a vehicle! Be sure the search bar at the top of the page has the exact address you want to leave from.',
                  })
                : intl.formatMessage({
                    id: 'goiKAF',
                    defaultMessage:
                      'Okay, great! Already know where you want the vehicle set up? Enter the exact address in the search bar at the top of the page.',
                  })}
            </Text>
          </Notice>
        </div>
      )}
    </div>
  );
};

export default LegacyDeliveryFilter;
