import {
  Avatar,
  EAvatarSize,
  ETextStyleVariant,
  GeneralBrandmarkIcon,
  Text,
} from '@outdoorsyco/bonfire';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button, {
  ButtonSize,
  ButtonVariants,
  TButtonProps,
} from '@/components/switchback/Button/Button';
import { CARET_LARGE } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import { PriceSize } from '@/components/switchback/Price/Price';
import { PriceDetails } from '@/components/ui/ListingTile/PriceDetails';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { getPricingInformation } from '@/redux/selectors/listing/bill';

import { useBillModuleContext } from '../BillModuleContext';
import css from './ButtonFilledState.module.css';

export type TButtonFilledState = {
  text: ReactNode;
  /** right aligned description */
  linkText?: ReactNode;
  required?: boolean;
  omitCaret?: boolean;
  showODStayDeliveryButton?: boolean;
  listingName?: string;
  location?: string;
  listingAvatar?: string;
} & TButtonProps;

export const ButtonFilledState = ({
  text,
  linkText,
  required,
  omitCaret,
  showODStayDeliveryButton,
  listingName,
  location,
  listingAvatar,
  ...btnProps
}: TButtonFilledState) => {
  const { readOnly } = useBillModuleContext();
  const { disabled } = btnProps;
  const isMobile = useBreakpoint();
  const price = useSelector(getPricingInformation);

  if (showODStayDeliveryButton) {
    return (
      <Button
        size={ButtonSize.large}
        variant={ButtonVariants.borderless}
        className={css.button}
        {...btnProps}
        disabled={disabled || readOnly}>
        <div>
          <Text
            variant={isMobile ? ETextStyleVariant.SmallBold : ETextStyleVariant.MediumBold}
            className="mb-2 text-left min-w-[100px]">
            {text}
          </Text>

          {!disabled && (
            <div className="flex items-center justify-between min-w-0 text-gray-900">
              <div className="text-left">
                <Text variant={ETextStyleVariant.SmallRegular} className="mb-1">
                  {listingName}
                </Text>
                <Text variant={ETextStyleVariant.SmallRegular}>{location}</Text>
                {price.sibling && (
                  <div className="mt-1">
                    <PriceDetails
                      {...price.sibling}
                      showDiscount
                      originalPrice={price.sibling.overrideDayPrice || price.sibling.originalPrice}
                      size={PriceSize.small}
                      priceClassName="[&>p>span]:!text-sm [&>p>span]:!font-normal"
                    />
                  </div>
                )}
              </div>
              <div className="flex items-center">
                {listingAvatar && (
                  <Avatar
                    ariaLabel={listingName}
                    defaultBrandIcon={GeneralBrandmarkIcon}
                    imageUrl={listingAvatar}
                    size={EAvatarSize.Medium}
                  />
                )}

                {!omitCaret && !readOnly && (
                  <Icon
                    className="ml-1 shrink-0 transform rotate-180"
                    name={CARET_LARGE}
                    size={IconSize.large}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </Button>
    );
  }

  return (
    <Button
      size={ButtonSize.large}
      variant={ButtonVariants.borderless}
      className={css.button}
      {...btnProps}
      disabled={disabled || readOnly}>
      <div className="flex items-center min-w-0">
        <div className="flex-grow font-medium text-left min-w-[100px]">
          {text}
          {required && (
            <span className={css.required}>
              (<FormattedMessage defaultMessage="required" id="+gRnOa" />)
            </span>
          )}
        </div>
        {!disabled && (
          <span className="flex min-w-0 ml-4 text-gray-900">
            <span className="text-right truncate text-300">{linkText}</span>
            {!omitCaret && !readOnly && (
              <Icon
                className="ml-1 shrink-0 transform rotate-180"
                name={CARET_LARGE}
                size={IconSize.large}
              />
            )}
          </span>
        )}
      </div>
    </Button>
  );
};
