import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuTriggerState } from 'react-stately';

import Loading from '@/components/switchback/Loading/Loading';
import { EFilterCategories } from '@/constants/searchFilters';
import { setSelectedFilter } from '@/redux/modules/search';
import { TRootState } from '@/redux/rootReducer';

import FilterDropdownContent from '../FilterDropdownContent/FilterDropdownContent';
import { PriceFilterContainer } from '../RentalFilters/PriceFilter/PriceFilterContainer';
import FilterDropdown from './FilterDropdown';

const loading = () => (
  <div className="flex items-center justify-center w-full h-12">
    <Loading />
  </div>
);
loading.displayName = 'loader';

interface INewFilterDropdownContainerProps {
  show: boolean;
  triggerState: MenuTriggerState;
  triggerRef: React.RefObject<HTMLElement>;
  onDismiss: () => void;
  filterFunctions: Partial<
    Record<EFilterCategories, { onSubmit: () => void; onReset: () => void; onDismiss: () => void }>
  >;
}

const FilterDropdownContainer: React.FC<INewFilterDropdownContainerProps> = ({
  onDismiss,
  triggerState,
  show,
  triggerRef,
  filterFunctions,
}) => {
  const dispatch = useDispatch();

  const selectedFilter = useSelector<TRootState, EFilterCategories | null>(
    state => state.search.selectedFilter,
  );

  const [layoutCache, setLayoutCache] = useState(0);

  const closeFilter = useCallback(() => {
    triggerState.close();
    dispatch(setSelectedFilter(null));
    setLayoutCache(layoutCache + 1);
  }, [dispatch, layoutCache, triggerState]);

  const handleDismiss = useCallback(() => {
    if (selectedFilter) {
      filterFunctions?.[selectedFilter]?.onDismiss();
    }

    onDismiss();
  }, [filterFunctions, onDismiss, selectedFilter]);

  const onReset = () => {
    if (selectedFilter) {
      filterFunctions?.[selectedFilter]?.onReset();
    }
  };

  const onSubmit = () => {
    if (selectedFilter) {
      filterFunctions?.[selectedFilter]?.onSubmit();
    }
    closeFilter();
  };

  const renderFilter = () => {
    if (selectedFilter === EFilterCategories.PRICE) {
      return (
        <FilterDropdownContent onReset={onReset} onSubmit={onSubmit}>
          <div className="md:w-96 md:p-[3px]">
            <PriceFilterContainer show={show} showConfirm={false} />
          </div>
        </FilterDropdownContent>
      );
    }
  };

  return (
    <FilterDropdown
      key={layoutCache}
      show={show}
      triggerState={triggerState}
      onDismiss={handleDismiss}
      triggerRef={triggerRef}>
      {renderFilter()}
    </FilterDropdown>
  );
};

export default FilterDropdownContainer;
